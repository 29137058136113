import React from 'react';
import PropTypes from 'prop-types';

const Hamburger = ( { isActive, onClick } ) => {
	return (
		<>
			<div className={`hamburger ${ isActive ? 'activeHamburger' : '' }`} onClick={ onClick }>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.32 18" width="32.32" height="16">
					<rect
						fill="currentColor"
						x="2.22"
						y={!isActive ? '0' : '1'}
						width="32.32"
						height="1"
						rx="1.26"
						ry="1.26"
						transform={isActive ? 'translate(12 -4.97) rotate(45)' : ''}
						className="transition"
					/>
					<rect
						fill="currentColor"
						x="2.22"
						y={!isActive ? '8' : '17'}
						width="32.32"
						height="1"
						rx="1.26"
						ry="1.26"
						transform={isActive ? 'translate(-4.97 12) rotate(-45)' : ''}
						className="transition"
					/>
					<rect
						fill={!isActive ? 'currentColor' : 'none'}
						x="2.22"
						y="16"
						width="32.32"
						height="1"
						rx="1.26"
						ry="1.26"
						transform={isActive ? 'translate(-4.97 12) rotate(-45)' : ''}
						className="transition"
					/>
				</svg>
			</div>
		</>
	);
};

Hamburger.defaultProps = { onClick: () => null };

Hamburger.propTypes = {
	isActive: PropTypes.bool,
	onClick: PropTypes.func
};

export default Hamburger;
