import React from 'react';
import PropTypes from 'prop-types';

import MobileNavAnchorLink from './MobileNavAnchorLink';

// import * as styles from './site-navigation.module.css';

const SiteNavigation = ( {
	disabled,
	navigation
} ) => {
	return (
		<nav className="siteNavigation">
			<ul>
				{ navigation.map( ( i ) => (
					<li key={ i } style={{ padding: 0 }}>
						<MobileNavAnchorLink disabled={ disabled } link={i}/>
					</li>
				) ) }
			</ul>
		</nav>
	);
};


SiteNavigation.defaultProps = { className: '' };

SiteNavigation.propTypes = {
	disabled: PropTypes.bool,
	navigation: PropTypes.array
};

export default SiteNavigation;
