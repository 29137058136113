import React from 'react';
import PropTypes from 'prop-types';

const SemiCircle = ( { className } ) => {
	return (
		<svg className={ className } xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1672 70.17">
			<g id="Layer_fg" data-name="Layer fg">
				<g id="Layer_fg" data-name="Layer fg">
					<path d="M1672,0 1672,70.17 0,70.17 L836,0 1672,70.17Z" />
				</g>
			</g>
		</svg>
	);
};

SemiCircle.defaultProps = { className: '' };

SemiCircle.propTypes = { className: PropTypes.string };

export default SemiCircle;
