import React from 'react';
import PropTypes from 'prop-types';
import { AnchorLink } from "gatsby-plugin-anchor-links";

const DesktopAnchorLink = ( { link } ) => {

	var linkAnchor = link.replace(' ','_');

	return (
		<AnchorLink to={'/#A_' + linkAnchor} title={link}>
		<span>{link}</span>
	    </AnchorLink>
	);
};

DesktopAnchorLink.defaultProps = { className: '', link: '' };

DesktopAnchorLink.propTypes = { className: PropTypes.string,link: PropTypes.string };

export default DesktopAnchorLink;
