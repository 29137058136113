import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion, useCycle } from 'framer-motion';

import Hamburger from './Hamburger';

import { Link } from 'gatsby';
import MobileMenu from './MobileMenu';
import DesktopNavLink from './SiteNavigation/DesktopNavLink';
import DesktopAnchorLink from './SiteNavigation/DesktopAnchorLink';

import { toSlug, toTitle } from '../../utils/formatters';

const Header = ( { altlogo, logo, opacity, navigation, contact } ) => {
	const [ isActive, setIsActive ] = useState( false );
	const [ sticky, setSticky ] = useState( false );
	const [ isSticky, toggleIsSticky ] = useCycle( false, true );

	const navRef = useRef();

	const hamburgerToggle = () => {

		setIsActive( prev => !prev );
	};

	const forceClose = () => {
		setIsActive( false );

	};


	const anchorArr = navigation.desktopAnchors.split( ',' );

	useEffect( () => {
		const scrollListener = () => {
			window.scrollY === 0 && setSticky( false );
			window.scrollY > navRef.current.offsetHeight
				? !sticky && setSticky( true )
				:	sticky && setSticky( false );
		};

		window.addEventListener( 'scroll', scrollListener );

		return () => window.removeEventListener( 'scroll', scrollListener );
	}, [] );

	useEffect( () => {
		const stickySetter = () => {
			toggleIsSticky();
		};

		return () => stickySetter();
	}, [ sticky ] );

	return (
		<motion.header
			initial={ sticky }
			animate={ isSticky ? 'sticky' : 'default' }
			variants={{
				default: {
					backgroundColor:
					opacity
						? contact
							? 'rgba(102, 45, 145, 0.1)'
							: 'rgba(102, 45, 145, 1)'
						: '#041a2f'
				},
				sticky: { backgroundColor: '#662d91' }
			}}
			transition={{ duration: 0.3, ease: 'easeInOut' }}
			ref={navRef}
			className="siteHeader"
		>
			<div className={`navWrapper ${ contact ? 'text-primary-black' : '' }`}>
				
				<Link to="/">
					<img
						alt="Progmentum Logo"
						className= {sticky ? 'header_logo' : 'header_hide'}
						src={ logo }
					/>
				</Link>
				<AnimatePresence>
					{isActive && <MobileMenu close={forceClose} open={isActive} navigation={ anchorArr } />}
				</AnimatePresence>
				<Hamburger
					isActive={isActive}
					onClick={hamburgerToggle}
				/>
				
				<ul className="rightNav">
					{ anchorArr
						.map( ( i ) => (
							<li key={ i }>
								<DesktopAnchorLink
									link={ i }
								/>
							</li> ) )
					}
				</ul>
				
			</div>
		</motion.header>
	);
};

Header.defaultProps = { opacity: true };

Header.propTypes = {
	altlogo: PropTypes.string,
	logo: PropTypes.string,
	opacity: PropTypes.bool,
	navigation: PropTypes.object,
	contact: PropTypes.bool
};

export default Header;
