/* eslint-disable multiline-comment-style */
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { AnimatePresence, motion } from 'framer-motion';
import { navigate } from 'gatsby';

import SEO from '../../../components/seo';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

const LayoutTemplate = ( { children, companyInfo, logo, altlogo, title, preview } ) => {
	useEffect( () => console.log( Cookies() ), [] );

	const assembleNavItems = useCallback( navArr => navArr.map( navItem => {
		const { navigation } = companyInfo;

		if ( !navigation.associatedPageGroups ) {
			return { page: navItem };
		}

		const associated = navigation.associatedPageGroups.find( associatedPage => associatedPage.category.pageName === navItem );

		return {
			page: navItem,
			associatedPages: associated ? associated.category.associatedPages : undefined
		};
	} ), [ companyInfo ] );

	const navigation = useMemo( () => {
		const { desktop, desktopAnchors, footer, mobile } = companyInfo.navigation;

		return {
			desktopAnchors: desktopAnchors,
			desktop: assembleNavItems( desktop ),
			footer: assembleNavItems( footer ),
			mobile: assembleNavItems( mobile )
		};
	}, [ assembleNavItems, companyInfo.navigation ] );

	return (
		<>
			<CookieConsent
				location="bottom"
				enableDeclineButton
				declineButtonClasses="button primaryButton"
				onDecline={ () => navigate( '/privacy' )}
				buttonClasses="button primaryButton"
				buttonText="Accept"
				containerClasses="cookieConsent"
				declineButtonText="Learn more"
				cookieName="gatsby-gdpr-google-analytics"
			>
					We use cookies to provide and improve our services. By using our site, you consent to these cookies.
			</CookieConsent>
			<motion.div
				initial={{ opacity: 0.4 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.9, ease: 'easeInOut' }}
				className="siteWrapper">
				{!preview
				&& <SEO title={ title } />
				}
				<Header
					logo={ logo }
					altlogo = { altlogo }
					opacity={
						!preview
					&& ( title !== 'home' && title !== 'terms and conditions' && title !== 'privacy' ) }
					contact={ title === 'contact us' }
					navigation={ navigation }
				/>
				<AnimatePresence>
					<>
						{ children }
					</>
				</AnimatePresence>
				<Footer logo={ logo } companyInfo={ companyInfo } navigation={ navigation.footer }/>
			</motion.div>
		</>
	);
};

LayoutTemplate.defaultProps = { preview: false };

LayoutTemplate.propTypes = {
	children: PropTypes.node,
	logo: PropTypes.string,
	altlogo: PropTypes.string,
	companyInfo: PropTypes.object,
	title: PropTypes.string,
	preview: PropTypes.bool
};

export default LayoutTemplate;
