import React from 'react';
import PropTypes from 'prop-types';

import useCompanyInfo from '../../hooks/useCompanyInfo';
import LayoutTemplate from '../../templates/components/Layout';

const Layout = ( { title, children } ) => {
	const companyInfo = useCompanyInfo();

	return (
		<LayoutTemplate title={ title } logo={ companyInfo.headerLogo.childImageSharp.gatsbyImageData.images.fallback.src } 
		altlogo={ companyInfo.headerAltLogo.childImageSharp.gatsbyImageData.images.fallback.src } 
			companyInfo={ companyInfo }>
			{ children }
		</LayoutTemplate>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string
};

export default Layout;
